// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apis-detection-on-demand-js": () => import("./../src/pages/apis/detection-on-demand.js" /* webpackChunkName: "component---src-pages-apis-detection-on-demand-js" */),
  "component---src-pages-apis-helix-js": () => import("./../src/pages/apis/helix.js" /* webpackChunkName: "component---src-pages-apis-helix-js" */),
  "component---src-pages-apis-js": () => import("./../src/pages/apis.js" /* webpackChunkName: "component---src-pages-apis-js" */),
  "component---src-pages-apis-lighthouse-js": () => import("./../src/pages/apis/lighthouse.js" /* webpackChunkName: "component---src-pages-apis-lighthouse-js" */),
  "component---src-pages-apis-messagebus-js": () => import("./../src/pages/apis/messagebus.js" /* webpackChunkName: "component---src-pages-apis-messagebus-js" */),
  "component---src-pages-apis-platform-service-js": () => import("./../src/pages/apis/platform-service.js" /* webpackChunkName: "component---src-pages-apis-platform-service-js" */),
  "component---src-pages-apis-plugin-manager-js": () => import("./../src/pages/apis/plugin-manager.js" /* webpackChunkName: "component---src-pages-apis-plugin-manager-js" */),
  "component---src-pages-endpoint-apis-js": () => import("./../src/pages/endpoint-apis.js" /* webpackChunkName: "component---src-pages-endpoint-apis-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-detection-on-demand-js": () => import("./../src/templates/detection-on-demand.js" /* webpackChunkName: "component---src-templates-detection-on-demand-js" */),
  "component---src-templates-endpoint-js": () => import("./../src/templates/endpoint.js" /* webpackChunkName: "component---src-templates-endpoint-js" */),
  "component---src-templates-helix-js": () => import("./../src/templates/helix.js" /* webpackChunkName: "component---src-templates-helix-js" */)
}

